<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Sign-ups" subtitle="See who has registered an interest in volunteering and pre-approve them." :readonly="$authorised('con/volunteers/access', 'read')">

		<app-content-head-actions-item text="Approve" icon="approve" :success="true" :disabled="!selected.length || is.rejecting" :loading="is.accepting" v-on:click="onAcceptClick" />
		<app-content-head-actions-item text="Reject" icon="reject.thumb" :danger="true" :disabled="!selected.length || is.accepting" :loading="is.rejecting" v-on:click="onRejectClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no badge holders found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Date" />
				<app-table-column text="Reference" />
				<app-table-column text="Name" />
				<app-table-column text="About" />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row :noClick="true" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.date | localDate('MM/DD/YYYY h:mma')" />
					<app-table-cell-text :text="item.badge.reference" />
					<app-table-cell-text :text="item.badge.name" />
					<app-table-cell-text :text="item.about" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			is: {
				accepting: false,
				rejecting: false
			},
			layout: '150px 100px 200px auto 24px',
			endpoint: 'convention/volunteers/signups',
			live: 'convention/volunteers/signups'
		}

	},

	methods: {

		onAcceptClick: function() {

			this.is.accepting = true

			this.$api.patch('convention/volunteers/signups', {
				index: this.selected,
				action: 'accept'
			}).then(function() {

				this.is.accepting = false

				this.$notify({
					message: 'The selected sign-ups were approved.'
				})

				this.loadRefresh()

			}.bind(this))

		},

		onRejectClick: function() {

			this.is.rejecting = true

			this.$api.patch('convention/volunteers/signups', {
				index: this.selected,
				action: 'reject'
			}).then(function() {

				this.is.rejecting = false

				this.$notify({
					message: 'The selected sign-ups were rejected.'
				})

				this.loadRefresh()

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>